import * as React from "react"
import { env, IS_PRODUCTION } from "lib/config"
import { useLocalStorage } from "lib/hooks/useLocalStorage"
import { Box, Button, HStack, IconButton, Slide, Stack, Text, useDisclosure, Image } from "@chakra-ui/react"
import dayjs from "dayjs"
import { CloseIcon } from "@chakra-ui/icons"
import { useBetterTranslation } from "lib/hooks/useTranslation"
import { Modal } from "./Modal"

const BANNER_TOKEN = `gm.banner.ade24.status${IS_PRODUCTION ? "" : `.${env}`}`
const EVENT_DATE = "2024-10-17"

export default function Banner() {
  const bt = useBetterTranslation()
  const [status, setStatus] = useLocalStorage(BANNER_TOKEN, "enabled")
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: status === "enabled" })
  const modalProps = useDisclosure()

  return status === "disabled" || dayjs().isAfter(dayjs(EVENT_DATE).endOf("day")) ? null : (
    <>
      <Slide direction="top" in={isOpen} style={{ zIndex: 10 }}>
        <Box px={6} py={4} bg="pink.500" mt={{ base: "88px", md: "80px" }} w="100%">
          <Stack pos="relative">
            <HStack spacing={2} justify="center" pr={12} wrap="wrap">
              <Text color="white" textAlign="center">
                {bt({
                  en: "Invitation to Grey Men ADE borrel!",
                  nl: "Uitnodiging Grey Men borrel tijdens ADE!",
                })}
              </Text>
              <Button variant="link" color="white" textDecor="underline" onClick={modalProps.onOpen}>
                {bt({ en: "read more", nl: "lees meer" })}
              </Button>
            </HStack>
            <IconButton
              aria-label="hide banner"
              icon={<CloseIcon color="white" boxSize="14px" />}
              variant="link"
              onClick={async () => {
                onToggle()
                await new Promise((res) => setTimeout(res, 500))
                setStatus("disabled")
              }}
              pos="absolute"
              h="100%"
              right={0}
            />
          </Stack>
        </Box>
      </Slide>
      <Modal
        {...modalProps}
        title={bt({ en: "The annual Grey Men ADE party!", nl: "De jaarlijkse Grey Men borrel tijdens ADE!" })}
        size="xl"
      >
        <Stack>
          <Text>
            {bt({
              en: "This edition we leave the Japanese behind us and move the party to Trees, in a historic building on the Zeedijk (14A, 1012 AX Amsterdam). They will provide delicious fine dining and cocktails, we will prepare a great line-up of DJs. This will all take place on Thursday October 17th from 17:00. Will we see you there?",
              nl: "Deze editie laten we de Japanner achter ons en verplaatsen we het feest naar Trees, in een historisch pand op de Zeedijk (14A, 1012 AX Amsterdam). Zij zorgen voor heerlijke fine dining en cocktails, wij stomen een geweldige line-up aan dj's klaar. Dit alles vindt plaats op donderdag 17 oktober vanaf 17:00. Zien we je daar?",
            })}
          </Text>
          <Image alt="ADE 2024 poster" src="/ade2024.png" maxH="100%" w="100%" objectFit="contain" />
        </Stack>
      </Modal>
    </>
  )
}
