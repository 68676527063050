import type { BoxProps } from "@chakra-ui/react"
import { Badge } from "@chakra-ui/react"

import type { IncomeDataStatus, ReportStatus } from "lib/graphql"
import { useBetterTranslation } from "lib/hooks/useTranslation"

interface StatusProps extends BoxProps {
  status: ReportStatus | IncomeDataStatus
}

export function Status(props: StatusProps) {
  const bt = useBetterTranslation()
  let colorScheme = "gray"
  let text = "N/A"
  switch (props.status) {
    case "PENDING":
      colorScheme = "orange"
      text = bt({ en: "To approve", nl: "Te accorderen" })
      break
    // DEPRECATED
    // case "FILED":
    //   colorScheme = "blue"
    //   text = bt({ en: "Filed", nl: "Opgeslagen" })
    //   break
    case "APPROVED":
      colorScheme = "green"
      text = bt({ en: "Approved", nl: "Goedgekeurd" })
      break
    case "REJECTED":
      colorScheme = "red"
      text = bt({ en: "Rejected", nl: "Afgewezen" })
      break
    case "COMPLETED":
      colorScheme = "green"
      text = bt({ en: "Completed", nl: "Voltooid" })
      break
  }
  return (
    <Badge colorScheme={colorScheme} {...props}>
      {text}
    </Badge>
  )
}
